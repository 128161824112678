@use 'variable/colors' as default-colors;

.scroll-y {
  overflow-y: auto !important;
  max-height: calc(100dvh - 208px);

  &::-webkit-scrollbar {
    max-width: 3.2px !important;
  }
}

.scroll-x {
  overflow-x: auto;

  &::-webkit-scrollbar {
    max-height: 3.2px !important;
  }
}

.scroll-x,
.scroll-y {
  &::-webkit-scrollbar-track {
    background-color: default-colors.$bg-white;
    border-radius: 12px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: default-colors.$gray-2;
    width: 3.2px !important;
    border-radius: 12px !important;
  }
}
