@use 'mixin/breakpoints' as media;

$sizes: 10, 12, 13, 14, 16, 18, 20, 22, 24, 26, 32, 36, 48;

@each $size in $sizes {
  .fs-#{$size}px {
    font-size: #{$size}px !important;
  }

  .fs-sm-#{$size}px {
    @include media.min-sm {
      font-size: #{$size}px !important;
    }
  }

  .fs-md-#{$size}px {
    @include media.min-md {
      font-size: #{$size}px !important;
    }
  }

  .fs-lg-#{$size}px {
    @include media.min-lg {
      font-size: #{$size}px !important;
    }
  }

  .fs-xl-#{$size}px {
    @include media.min-xl {
      font-size: #{$size}px !important;
    }
  }

  .fs-xxl-#{$size}px {
    @include media.min-xxl {
      font-size: #{$size}px !important;
    }
  }
}
