/* You can add global styles to this file, and also import other style files */
@use 'main';

/* Importing Bootstrap SCSS file. */
@use 'bootstrap/scss/bootstrap';

/* Importing Bootstrap PrimeNG files. */
@use 'primeng/resources/themes/lara-light-blue/theme.css';
@use 'primeng/resources/primeng.css';

.p-column-filter-overlay {
  padding: 16px;

  input {
    padding: 8px 12px;
    margin-bottom: 8px;
  }

  p-dropdown {
    display: none;
  }

  .p-column-filter-add-rule {
    display: none;
  }

  .p-column-filter-buttonbar {
    .p-button-sm {
      gap: 8px;
      padding: 12px 16px;
      border-radius: 8px;
      background-color: #202c5f;
      color: #ffff;
      border: 0;
      width: 100%;

      &:hover {
        background-color: #1a244e;
      }
    }

    .p-button-outlined {
      background-color: #ffff;
      color: #42425e;
      outline: none;

      &:hover {
        background-color: #ffff;
        text-decoration: underline;
        color: #202c5f;
      }
    }
  }
}
