@use "variable/colors" as colors;

.tooltip-container {
  position: absolute;
  display: inline-block;
  margin-top: -12px;
}

.tooltip-container .tooltip-content {
  visibility: hidden;
  width: 120px;
  background-color: colors.$gray-4;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  position: absolute;
  z-index: 99999;
  top: 125%;
  left: 50%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container .tooltip-content::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent colors.$gray-4 transparent;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}
