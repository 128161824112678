@use 'variable/colors' as colors;

.text-default {
  color: colors.$text-default !important;
}

.text-gray-0 {
  color: colors.$text-gray-0 !important;
}

.text-gray-1 {
  color: colors.$text-gray-1 !important;
}

.text-gray-2 {
  color: colors.$text-gray-2 !important;
}

.text-gray-3 {
  color: colors.$text-gray-3 !important;
}

.text-gray-4 {
  color: colors.$text-gray-4 !important;
}

.text-primary {
  color: colors.$primary !important;
}

.text-secondary {
  color: colors.$secondary !important;
}

.text-success {
  color: colors.$success !important;
}

.text-warning {
  color: colors.$warning !important;
}

.text-danger {
  color: colors.$danger !important;
}
