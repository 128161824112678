@use 'mixin/breakpoints';

@use 'util/animations';
@use 'util/colors/background';
@use 'util/colors/border';
@use 'util/colors/text';
@use 'util/cursor';
@use 'util/effects';
@use 'util/font-sizes';
@use 'util/font-weights';
@use 'util/fonts';
@use 'util/line-heights';
@use 'util/scroll';
@use 'util/tooltip';

@use 'variable/colors' as color;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  background-color: color.$bg-default;
  color: color.$text-default;
}
