@use 'variable/colors' as colors;

// Cores de background
.bg-default {
  background-color: colors.$bg-default !important;
}

.bg-backdrop {
  background-color: colors.$bg-backdrop !important;
}

.bg-primary {
  background-color: colors.$primary !important;
}

.bg-primary-light {
  background-color: colors.$primary-light !important;
}

.bg-secondary {
  background-color: colors.$secondary !important;
}

.bg-secondary-light {
  background-color: colors.$secondary-light !important;
}

.bg-success {
  background-color: colors.$success !important;
}

.bg-success-light {
  background-color: colors.$success-light !important;
}

.bg-warning {
  background-color: colors.$warning !important;
}

.bg-warning-light {
  background-color: colors.$warning-light !important;
}

.bg-danger {
  background-color: colors.$danger !important;
}

.bg-danger-light {
  background-color: colors.$danger-light !important;
}

.bg-gray-1 {
  background-color: colors.$gray-1 !important;
}

.bg-gray-2 {
  background-color: colors.$gray-2 !important;
}

.bg-gray-3 {
  background-color: colors.$gray-3 !important;
}

.bg-gray-4 {
  background-color: colors.$gray-4 !important;
}

