// Paleta primária
$primary: #202c5f;
$primary-dark: #1a244e;
$primary-light: #{$primary}1a;
$secondary: #417838;
$secondary-light: #{$secondary}1a;

// Outras Cores
$success: #188a42;
$warning: #ae510f;
$danger: #d01313;
$success-light: #caf1d8;
$warning-light: #feddc7;
$danger-light: #ffd0ce;

// Tons de cinza
$gray-0: #f4f5fa;
$gray-1: #e3e3ea;
$gray-2: #bbbbcf;
$gray-3: #71718b;
$gray-4: #42425e;

$bg-white: #ffffff;
$bg-default: $gray-0;
$bg-backdrop: #0000001a;

// Cores de texto
$text-white: #ffffff;
$text-default: $gray-4;
$text-gray-0: $gray-0;
$text-gray-1: $gray-1;
$text-gray-2: $gray-2;
$text-gray-3: $gray-3;
$text-gray-4: $gray-4;
